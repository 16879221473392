












































































































import { Button } from "element-ui";
import { Vue, Component } from "vue-property-decorator";
import Homeslider from "@/components/UIComponents/Homeslider.vue";
import {
  CropStore,
  LoadStore,
  DemandSupplyStore,
  CropVarietyStore,
  JWTStore,
  MetaStore
} from "@/store/modules";
import Cropstat from "@/components/UIComponents/Cropstat.vue";
import Recentvarieties from "@/components/UIComponents/Recentvarieties.vue";
import Marketstat from "@/components/UIComponents/Marketstat.vue";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";

@Component({
  metaInfo: {
    title: "Choose Allocation",
    meta: [
      { name: "keyword", content: "allocation,page" },
      {
        name: "description",
        content: "Allocation Selection Page",
        vmid: "allocation"
      }
    ]
  }
})
export default class ChooseAllocation extends Vue {
  loading: boolean = true;

  allocation: any = { autoAllocation: true };
  role: string = "";
  noAllocation: boolean = false;

  // get crops() {
  //   return CropStore.DSADCropList;
  // }

  get noAutoAllocation() {
    return !this.allocation.autoAllocation;
  }

  get fiscalYears() {
    return MetaStore.FiscalYear;
  }

  get crops() {
    let crops = DemandSupplyStore.FyWiseSubsidyCrops.map(x => {
      return { fiscal_year: x.fiscal_year, name: x.crop_name, id: x.crop_type };
    });
    if (this.allocation && this.allocation.fiscalyearid) {
      crops = crops.filter(x => x.fiscal_year == this.allocation.fiscalyearid);
    }
    crops = [...new Map(crops.map(item => [item["id"], item])).values()];
    return crops;
  }

  get varieties() {
    let cropVarieties = DemandSupplyStore.FyWiseSubsidy.map(x => {
      return {
        fiscal_year: x.fiscal_year,
        crop_type: x.crop_type,
        name: x.variety_name,
        id: x.crop_variety
      };
    });
    if (this.allocation && (this.allocation.fiscalyearid || this.allocation.crop_type)) {
      if (this.allocation.fiscalyearid) {
        cropVarieties = cropVarieties.filter(x => x.fiscal_year == this.allocation.fiscalyearid);
      }
      if (this.allocation.crop_type) {
        cropVarieties = cropVarieties.filter(x => x.crop_type == this.allocation.crop_type);
      }
    }
    // Returns only unique variety
    cropVarieties = [...new Map(cropVarieties.map(item => [item["id"], item])).values()];
    return cropVarieties;
  }

  get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }

  // get varieties() {
  //   let varieties = CropVarietyStore.CropVarieties;
  //   return varieties;
  // }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne") {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  async submit() {
    let isValid = await this.$validator.validateAll();

    if (isValid) {
      if (this.role == "ISSMAdmin" || this.role == "ISMAdmin") {
        this.$router.push({
          name: "Allocation",
          query: {
            cropGroupId: this.allocation.crop_type,
            varietyId: this.allocation.cropvarietyid,
            fiscalYearId: this.allocation.fiscalyearid,
            noAutoAllocation: String(this.noAutoAllocation),
            seedTypeId: String(4)
          }
        });
      }
    }
  }

  // async submit() {
  //   let isValid = await this.$validator.validateAll();
  //   console.log("inside submit! ")
  //   if (isValid) {
  //     let batchId = this.crops.filter(x => x.groupid == this.allocation.dsadgroupid)[0].batchid;
  //     // if role is ISMAdmin (Improved Seed Manager Admin), then pass seedTypeId = 4 (id of seedtype 'Improved Seed' )
  //     if (batchId && this.role == "ISMAdmin") {
  //       this.$router
  //         .push({
  //           name: "Allocation",
  //           query: {
  //             cropGroupId: this.allocation.dsadgroupid,
  //             varietyId: this.allocation.cropvarietyid,
  //             seedTypeId: String(4),
  //             batchId: String(batchId)
  //           }
  //         })
  //         .catch(error => {});
  //     } else if (batchId) {
  //       this.$router
  //         .push({
  //           name: "Allocation",
  //           query: {
  //             cropGroupId: this.allocation.dsadgroupid,
  //             varietyId: this.allocation.cropvarietyid,
  //             seedTypeId: this.allocation.seedtypeid,
  //             batchId: String(batchId)
  //           }
  //         })
  //         .catch(error => {});
  //     }
  //   }
  // }

  async created() {
    this.role = JWTStore.role;

    this.loading = true;

    DemandSupplyStore.getAllSeedType();
    // DemandSupplyStore.getSubsidyCrops();
    // CropVarietyStore.getAllCropVariety();
    DemandSupplyStore.getFyWiseSubsidyCrops({ lang: this.$i18n.locale });
    DemandSupplyStore.getFyWiseSubsidy({ lang: this.$i18n.locale });
    MetaStore.getAllFiscalYear();
    await CropStore.getDSADCropName({ phase: "allocation" });

    this.loading = false;
  }

  // loadCropVariety(event: any) {
  //   this.allocation.cropvarietyid = null as any;
  //   let cropGroupId = this.crops.filter(x => x.groupid == event)[0].groupid;
  //   if (cropGroupId != undefined) {
  //     CropVarietyStore.getAllowedCropVarietiesByCropId({id: cropGroupId, lang: this.$i18n.locale});
  //   }
  // }
}
