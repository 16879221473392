































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import {
  CropStore,
  LoadStore,
  DemandSupplyStore,
  CropVarietyStore,
  DSADStore
} from "@/store/modules";
import { AllocationData, DistributionExcelReport } from "@/store/models/dsad";
import { MetaInfo } from "vue-meta";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";

@Component({
  metaInfo(): MetaInfo {
    return {
      title: "Allocation Detail",
      meta: [
        { name: "keyword", content: "Sqcc allocation list" },
        {
          name: "description",
          content: "List of Allocation of seeds",
          vmid: "demandList"
        }
      ]
    };
  }
})
export default class Allocation extends Vue {
  $refs!: {
    allocationTable: InstanceType<typeof Vue>;
  };
  cropGroupId: number = 0;
  varietyId: number = 0;
  seedTypeId: number = 0;
  batchId: number = 0;
  fiscalYearId: number = 0;
  urlquery: any;
  report: DistributionExcelReport = {} as DistributionExcelReport;

  get dataList() {
    if (DSADStore.Allocation.data) {
      return DSADStore.Allocation.data;
    }
    return [];
  }

  get header() {
    if (DSADStore.Allocation.header) {
      return DSADStore.Allocation.header;
    }
    return [];
  }

  get allocationreport() {
    return DSADStore.Allocation.allocationreport;
  }

  get distributionreport() {
    return DSADStore.Allocation.distributionreport;
  }

  get distributionexcelreport() {
    return DSADStore.DistributionExcelReport;
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne") {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  async downloadExcelReportDistribution() {
    let params = {
      batchid: this.dataList[0].batchid,
      id: this.dataList[0].id
    };
    await DSADStore.downloadexcelreportdistribution(params);
    if (this.distributionexcelreport.url !== undefined) {
      var link = document.createElement("a");
      link.setAttribute("href", this.distributionexcelreport.url);
      link.click();
    }
  }

  get vhToPx() {
    return (vh: number) => {
      const vhPercent = vh / 100;
      return document.documentElement.clientHeight * vhPercent;
    };
  }

  get maxTableHeight() {
    return (vh: number) => {
      let px = this.vhToPx(vh);
      if (px < 500) {
        return 500;
      }
      return px;
    };
  }
  rerenderTable = 0;

  async scrollToTable() {
    this.$refs.allocationTable.$el.scrollIntoView();
  }

  calculateRemainingSupply(supply: any) {
    let remain: any = supply.soldquantity;
    if (this.dataList) {
      for (let data of this.dataList) {
        remain = remain - (data as any)[supply.prop].allocationquantity;
      }
    }
    supply.remain = remain;
    return remain;
  }

  calculateRemainingDistributionSupply(supply: any) {
    let remain: any = supply.soldquantity;
    if (this.dataList) {
      for (let data of this.dataList) {
        remain = remain - (data as any)[supply.prop].distributionquantity;
      }
    }
    supply.remain = remain;
    return remain;
  }

  calculateRemainingAllocation(supply: any) {
    let remain: any = supply.total_allocation;
    if (this.dataList) {
      for (let data of this.dataList) {
        remain = remain - (data as any)[supply.prop].distributionquantity;
      }
    }
    supply.remain = remain;
    return remain;
  }

  calculateRemainingDemand(demand: any) {
    let remain: any = demand.seedquantity;
    if (this.header) {
      for (let data of this.header) {
        remain = remain - demand[data.prop].allocationquantity;
      }
    }
    demand.remain = remain;
    return remain;
  }

  calculateRemainingDistribution(demand: any) {
    let remain: any = demand.total_allocation;
    if (this.header) {
      for (let data of this.header) {
        remain = remain - demand[data.prop].distributionquantity;
      }
    }
    demand.remain = remain;
    return remain;
  }

  get totalSupply() {
    let total = 0;
    if (this.header) {
      for (let supply of this.header) {
        console.log(supply.soldquantity);
        total += Number(supply.soldquantity);
      }
    }
    return total;
  }

  get totalDemand() {
    let total = 0;
    if (this.dataList) {
      for (let demand of this.dataList) {
        total += Number(demand.seedquantity);
      }
    }
    return total;
  }

  get remainingSupply() {
    let total = this.totalSupply;
    if (this.header && this.dataList) {
      for (let demand of this.dataList) {
        for (let supply of this.header) {
          total -= Number(demand[supply.prop].allocationquantity);
        }
      }
    }
    return total;
  }

  get remainingDemand() {
    let total = this.totalDemand;
    if (this.dataList) {
      for (let demand of this.dataList) {
        for (let supply of this.header) {
          total -= Number(demand[supply.prop].allocationquantity);
        }
      }
    }
    return total;
  }

  get crop() {
    let crop = DemandSupplyStore.SubsidyCrops.filter(x => x.crop_type == this.cropGroupId)[0];
    if (crop) {
      return crop;
    }
    return {};
  }

  get seedType() {
    let seedType = DemandSupplyStore.SeedTypeList.filter(x => x.id == this.seedTypeId)[0];
    if (seedType) {
      const seedName = seedType.name.split(" ");
      let name: string[] = [];
      for (let seed of seedName) {
        name.push(this.$t(seed.toLowerCase()).toString());
      }
      seedType.name = name.join(" ");
      return seedType;
    }
    return {};
  }

  get variety() {
    let variety = CropVarietyStore.CropVarieties.filter(x => x.id == this.varietyId)[0];
    if (variety) {
      return variety;
    }
    return {};
  }

  async saveData() {
    let isValid: boolean = true;
    if (this.header) {
      for (let header of this.header) {
        if (header.remain && header.remain < 0) {
          isValid = false;
        }
      }
    }
    for (let data of this.dataList) {
      if (data.remain && data.remain < 0) {
        isValid = false;
      }
    }
    if (isValid) {
      this.$notify.success("Saved Successfully");
    }
  }

  async created() {
    this.urlquery = this.$route.query.urlquery;
    if (this.$route.query.cropGroupId) {
      this.cropGroupId = Number(this.$route.query.cropGroupId);
    }

    if (this.$route.query.varietyId) {
      this.varietyId = Number(this.$route.query.varietyId);
    }

    if (this.$route.query.seedTypeId) {
      this.seedTypeId = Number(this.$route.query.seedTypeId);
    }

    if (this.$route.query.batchId) {
      this.batchId = Number(this.$route.query.batchId);
    }

    if (this.$route.query.fiscalYearId) {
      this.fiscalYearId = Number(this.$route.query.fiscalYearId);
    }

    DemandSupplyStore.getSubsidyCrops({ lang: this.$i18n.locale });
    DemandSupplyStore.getSubsidy({ lang: this.$i18n.locale });
    DemandSupplyStore.getAllSeedType();
    CropVarietyStore.getAllCropVariety();
    await DSADStore.getAllocation({
      fiscalYearId: this.fiscalYearId,
      cropGroupId: this.cropGroupId,
      varietyId: this.varietyId,
      seedTypeId: this.seedTypeId,
      batchId: this.batchId,
      lang: this.$i18n.locale,
      autoAllocate: false
    });
  }
}
