







































































































































































































































import { Vue, Component } from "vue-property-decorator";
import {
  CropStore,
  LoadStore,
  DemandSupplyStore,
  CropVarietyStore,
  DSADStore
} from "@/store/modules";
// import { Allocation, AllocationData } from "@/store/models/dsad";
import { MetaInfo } from "vue-meta";
import Varieties from "../SeedCatalog/CropVarieties.vue";
import { Demand } from "@/store/models/DemandAndSupply";
import Allocation from "./AllocationDetail.vue";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";

@Component({
  metaInfo(): MetaInfo {
    return {
      title: "Add Allocation",
      meta: [
        { name: "keyword", content: "Sqcc allocation list" },
        {
          name: "description",
          content: "List of Allocation of seeds",
          vmid: "demandList"
        }
      ]
    };
  }
})
export default class AllocationForm extends Vue {
  $refs!: {
    allocationTable: InstanceType<typeof Vue>;
  };
  cropGroupId: number = 0;
  varietyId: number = 0;
  seedTypeId: number = 0;
  batchId: number = 0;
  fiscalYearId: number = 0;
  noAutoAllocation: boolean = false;
  saving: boolean = false;
  loading: boolean = false;
  minWidth: number = 250;

  // get dataList() {
  //   if (DSADStore.Allocation.data) {
  //     return DSADStore.Allocation.data;
  //   }
  //   return [];
  // }

  get dataList() {
    if (DSADStore.Allocation.data) {
      return DSADStore.Allocation.data;
    }
    // let demand = DemandSupplyStore.Demands.filter(x => x.crop_type == this.cropGroupId);
    // console.log("demand -> ", DemandSupplyStore.Demands);
    // if (demand) {
    //   return demand;
    // }

    return [];
  }

  get allocated() {
    if (DSADStore.Allocation.status == "allocated") {
      return true;
    }
    return false;
  }

  get allocationReport() {
    if (DSADStore.Allocation.allocationreport) {
      return DSADStore.Allocation.allocationreport;
    }
    return null;
  }

  get header() {
    if (DSADStore.Allocation.header) {
      return DSADStore.Allocation.header.filter(x => x.soldquantity != 0);
    }
    return [];
  }

  get vhToPx() {
    return (vh: number) => {
      const vhPercent = vh / 100;
      return document.documentElement.clientHeight * vhPercent;
    };
  }

  get maxTableHeight() {
    return (vh: number) => {
      let px = this.vhToPx(vh);
      if (px < 500) {
        return 500;
      }
      return px;
    };
  }
  rerenderTable = 0;

  async scrollToTable() {
    this.$refs.allocationTable.$el.scrollIntoView();
  }

  get calculateRemainingSupply() {
    return (supply: any) => {
      let remain: any = supply.soldquantity;
      if (this.dataList) {
        for (let data of this.dataList) {
          remain = remain - (data as any)[supply.prop].allocationquantity;
        }
        let total = this.header.filter(x => x.prop == supply.prop)[0];
        for (let data of this.dataList) {
          (data as any)[supply.prop].remainingSupply = remain;
          if (total && total.seedquantity) {
            (data as any)[supply.prop].total = total.seedquantity;
          }
        }
      }
      supply.remain = remain;
      return remain;
    };
  }

  get calculateRemainingDemand() {
    return (demand: any) => {
      let remain: any = demand.seedquantity;
      if (this.header) {
        for (let data of this.header) {
          remain = remain - demand[data.prop].allocationquantity;
        }
      }
      demand.remain = remain;
      return remain;
    };
  }

  get totalSupply() {
    let total = 0;
    if (this.header) {
      for (let supply of this.header) {
        total += Number(supply.soldquantity);
      }
    }
    return total;
  }

  get totalDemand() {
    let total = 0;
    if (this.dataList) {
      for (let demand of this.dataList) {
        total += Number(demand.seedquantity);
      }
    }
    return total;
  }

  get remainingSupply() {
    let total = this.totalSupply;
    if (this.header && this.dataList) {
      for (let demand of this.dataList) {
        for (let supply of this.header) {
          total -= Number(demand[supply.prop].allocationquantity);
        }
      }
    }
    return total;
  }

  get remainingDemand() {
    let total = this.totalDemand;
    if (this.dataList) {
      for (let demand of this.dataList) {
        for (let supply of this.header) {
          total -= Number(demand[supply.prop].allocationquantity);
        }
      }
    }
    return total;
  }

  get crop() {
    let crop = DemandSupplyStore.SubsidyCrops.filter(x => x.crop_type == this.cropGroupId)[0];
    if (crop) {
      return crop;
    }
    return {};
  }

  // get demand() {
  //   let demand = DemandSupplyStore.Demands.filter(x => x.croptypeid == this.cropGroupId);
  //   if (demand) {
  //     return demand;
  //   }
  //   return [];
  // }

  get seedType() {
    let seedType = DemandSupplyStore.SeedTypeList.filter(x => x.id == this.seedTypeId)[0];
    if (seedType) {
      const seedName = seedType.name.split(" ");
      let name: string[] = [];
      for (let seed of seedName) {
        name.push(this.$t(seed.toLowerCase()).toString());
      }
      seedType.name = name.join(" ");
      return seedType;
    }
    return {};
  }

  get variety() {
    let variety = CropVarietyStore.CropVarieties.filter(x => x.id == this.varietyId)[0];

    // let variety = DemandSupplyStore.SubsidyCrops.filter(x => x.crop_variety == this.varietyId)[0];
    console.log(variety);
    if (variety) {
      return variety;
    }

    return {};
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne") {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  async saveData(send_mail = false, download_report = false) {
    let isValid: boolean = true;
    if (this.header) {
      if (this.header.length <= 0) {
        isValid = false;
        return this.$notify.error("No supplies are present");
      }
      for (let header of this.header) {
        if (header.remain && header.remain < 0) {
          isValid = false;
        }
      }
    }
    if (this.dataList.length <= 0) {
      isValid = false;
      return this.$notify.error("No demands are present");
    }
    for (let data of this.dataList) {
      if (data.remain && data.remain < 0) {
        isValid = false;
      }
    }
    if (isValid) {
      let message = "This will save the allocation in database. Continue?";
      if (send_mail) {
        message =
          "This will save the allocation and send emails to all organizations. The allocation cannot be edited or removed. Continue?";
      }
      this.$confirm(message, "Warning", {
        confirmButtonText: this.$t("submit").toString(),
        confirmButtonClass: "el-button--primary",
        cancelButtonText: "Cancel",
        showClose: false,
        type: "warning"
      })

        .then(async () => {
          this.saving = true;
          let data = {
            data: this.dataList,
            header: this.header,
            send_mail: send_mail,
            status: send_mail ? "allocated" : "saved"
          };
          let error = false;
          await DSADStore.postAllocation(data).catch((error: any) => {
            error = true;
          });
          this.saving = false;
          if (error) {
            this.$notify.error("Saving Failed");
          } else {
            this.$notify.success("Saved Successfully");
            this.calculateRemaining();
            if (download_report) {
              const url = this.allocationReport;
              if (url) {
                console.log("Downloading");
                const link = document.createElement("a");
                link.href = url;
                // link.setAttribute("target", "_blank"); //or any other extension
                // link.setAttribute("download", download_report); //or any other extension
                document.body.appendChild(link);
                link.click();
              }
            } else {
              let previousRouteName = this.$route.query.previousRouteName;
              if (previousRouteName) {
                previousRouteName = String(previousRouteName);
                this.$router.push({ name: previousRouteName }).catch(error => {});
              } else {
                this.$router.push({ name: "ChooseAllocation" }).catch(error => {});
              }
            }
          }
        })

        .catch(() => {
          this.$message({
            type: "info",
            message: "Submit cancelled"
          });
        });
    }
  }

  saveAndDownload() {
    this.saveData(false, true);
  }

  async calculateRemaining() {
    for (let demand of this.dataList) {
      this.calculateRemainingDemand(demand);
    }
    for (let header of this.header) {
      this.calculateRemainingSupply(header);
    }
  }

  async created() {
    if (this.$route.query.cropGroupId) {
      this.cropGroupId = Number(this.$route.query.cropGroupId);
    }

    if (this.$route.query.varietyId) {
      this.varietyId = Number(this.$route.query.varietyId);
    }

    if (this.$route.query.seedTypeId) {
      this.seedTypeId = Number(this.$route.query.seedTypeId);
    }

    if (this.$route.query.fiscalYearId) {
      this.fiscalYearId = Number(this.$route.query.fiscalYearId);
    }

    if (this.$route.query.batchId) {
      this.batchId = Number(this.$route.query.batchId);
    }

    if (this.$route.query.noAutoAllocation) {
      if (this.$route.query.noAutoAllocation == "true") {
        this.noAutoAllocation = true;
      }
    }

    // CropStore.getDSADCropName({ phase: "allocation" });
    DemandSupplyStore.getAllSeedType();
    DemandSupplyStore.getSubsidyCrops({ lang: this.$i18n.locale });
    DemandSupplyStore.getSubsidy({ lang: this.$i18n.locale });
    DemandSupplyStore.getAllDemands({ lang: this.$i18n.locale });
    CropVarietyStore.getAllCropVariety();
    this.loading = true;
    await DSADStore.getAllocation({
      cropGroupId: this.cropGroupId,
      varietyId: this.varietyId,
      seedTypeId: this.seedTypeId,
      batchId: this.batchId,
      fiscalYearId: this.fiscalYearId,
      autoAllocate: !this.noAutoAllocation,
      lang: this.$i18n.locale
    });
    this.loading = false;
    this.calculateRemaining();
  }
}
