



























































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { AllocationListFilter } from "@/store/models/dsad";
import { Demand } from "@/store/models/DemandAndSupply";
import {
  CropVarietyStore,
  CropStore,
  DemandSupplyStore,
  DSADStore,
  MetaStore,
  JWTStore
} from "@/store/modules";
import { MetaInfo } from "vue-meta";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";

@Component({
  components: {},
  metaInfo(): MetaInfo {
    return {
      title: "Allocation List",
      meta: [
        { name: "keyword", content: "Sqcc allocation list" },
        {
          name: "description",
          content: "List of Allocation of seeds",
          vmid: "demandList"
        }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchInput: string = "";
  cropId: number = 0;
  pageNum: number = 1;
  clusterId: number = 0;
  language: string = "";
  loading: boolean = false;
  detailVisible: boolean = false;
  allocation: any = {};
  role: string = "";

  allocationfilter = {
    fiscalyearid: null,
    croptypeid: null,
    cropvarietyid: null,
    seedtypeid: null
  } as AllocationListFilter;

  urlquery: string = "";

  get dataList() {
    return DSADStore.AdminAllocationList;
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne") {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  indexMethod(index: number) {
    let i = (this.dataList.page - 1) * this.dataList.page_size + index + 1;
    return this.nepaliUnicodeNumber(i);
  }

  thousandSeprator(amount: number) {
    if (amount == null) {
      return null;
    }
    amount = Number(amount);
    if (amount !== undefined || amount !== 0 || amount !== "0" || amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  viewDetail(index: number) {
    this.urlquery = this.routerReplacer();
    this.allocation = this.dataList.results[index];
    this.$router
      .push({
        name: "AdminAllocationDetail",
        query: {
          // cropGroupId: this.allocation.groupid,
          varietyId: this.allocation.cropvarietyid,
          seedTypeId: this.allocation.seedtypeid,
          // batchId: String(this.allocation.batchid),
          fiscalYearId: String(this.allocation.fiscal_year),
          urlquery: this.urlquery
        }
      })
      .catch(error => {});
  }

  async filter() {
    let query: any = { page: 1 };
    this.pageNum = 1;
    // if (this.allocationfilter.fiscalyearid != null) {
    //   query["fiscalyearid"] = String(this.allocationfilter.fiscalyearid);
    // }
    // if (this.allocationfilter.croptypeid != null) {
    //   query["croptypeid"] = String(this.allocationfilter.croptypeid);
    // }
    // if (this.allocationfilter.cropvarietyid != null) {
    //   query["cropvarietyid"] = String(this.allocationfilter.cropvarietyid);
    // }
    // if (this.allocationfilter.seedtypeid != null) {
    //   query["seedtypeid"] = String(this.allocationfilter.seedtypeid);
    // }
    query = this.routerReplacer();
    await DSADStore.getAdminAllocation(query);
  }

  routerReplacer(page?: number) {
    let query: any = {};
    query["status"] = "allocated";
    if (page != null) {
      query["page"] = String(page);
    }
    if (this.allocationfilter.fiscalyearid != null) {
      query["fiscalyearid"] = String(this.allocationfilter.fiscalyearid);
    }
    if (this.allocationfilter.croptypeid != null) {
      query["croptypeid"] = String(this.allocationfilter.croptypeid);
    }
    if (this.allocationfilter.cropvarietyid != null) {
      query["cropvarietyid"] = String(this.allocationfilter.cropvarietyid);
    }
    if (this.role == "ISMAdmin") {
      query["seedtypeid"] = String(4);
    } else if (this.allocationfilter.seedtypeid != null) {
      query["seedtypeid"] = String(this.allocationfilter.seedtypeid);
    }
    // if (this.allocationfilter.seedtypeid != null) {
    //   query["seedtypeid"] = String(this.allocationfilter.seedtypeid);
    // }
    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: query
        })
        .catch(error => {});
    }
    return query;
  }

  clearSearch() {
    let query: any = { page: 1 };
    this.pageNum = 1;
    this.allocationfilter.fiscalyearid = null;
    this.allocationfilter.croptypeid = null;
    this.allocationfilter.cropvarietyid = null;
    this.allocationfilter.seedtypeid = null;
    query = this.routerReplacer();
    DSADStore.getAdminAllocation(query);
  }

  get crops() {
    let crops = DemandSupplyStore.FyWiseSubsidyCrops.map(x => {
      return { fiscal_year: x.fiscal_year, name: x.crop_name, id: x.crop_type };
    });
    if (this.allocation && this.allocation.fiscalyearid) {
      crops = crops.filter(x => x.fiscal_year == this.allocation.fiscalyearid);
    }
    crops = [...new Map(crops.map(item => [item["id"], item])).values()];
    return crops;
  }

  get varieties() {
    let cropVarieties = DemandSupplyStore.FyWiseSubsidy.map(x => {
      return {
        fiscal_year: x.fiscal_year,
        croptypeid: x.crop_type,
        name: x.variety_name,
        id: x.crop_variety
      };
    });
    if (
      this.allocationfilter &&
      (this.allocationfilter.fiscalyearid || this.allocationfilter.croptypeid)
    ) {
      if (this.allocationfilter.fiscalyearid) {
        cropVarieties = cropVarieties.filter(
          x => x.fiscal_year == this.allocationfilter.fiscalyearid
        );
      }
      if (this.allocationfilter.croptypeid) {
        cropVarieties = cropVarieties.filter(x => x.croptypeid == this.allocationfilter.croptypeid);
      }
    }
    // Returns only unique variety
    cropVarieties = [...new Map(cropVarieties.map(item => [item["id"], item])).values()];
    return cropVarieties;
  }

  get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }

  changeCrop() {
    this.allocationfilter.cropvarietyid = null;
  }

  get fiscalyear() {
    let fiscalyr = MetaStore.FiscalYear;
    return fiscalyr;
  }

  async handlePageChange(val: number) {
    this.pageNum = val;
    this.loading = true;
    let query = this.routerReplacer(this.pageNum);
    await DSADStore.getAdminAllocation(query);
    this.loading = false;
  }

  async created() {
    this.role = JWTStore.role;
    this.language = this.$i18n.locale;
    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }
    this.loading = true;
    MetaStore.getAllFiscalYear();
    DemandSupplyStore.getAllSeedType();
    DemandSupplyStore.getFyWiseSubsidyCrops({ lang: this.$i18n.locale });
    DemandSupplyStore.getFyWiseSubsidy({ lang: this.$i18n.locale });
    if (this.$route.query.fiscalyearid) {
      this.allocationfilter.fiscalyearid = Number(this.$route.query.fiscalyearid);
    }

    if (this.$route.query.croptypeid) {
      this.allocationfilter.croptypeid = Number(this.$route.query.croptypeid);
    }

    if (this.$route.query.cropvarietyid) {
      this.allocationfilter.cropvarietyid = Number(this.$route.query.cropvarietyid);
    }

    if (this.$route.query.seedtypeid) {
      this.allocationfilter.seedtypeid = Number(this.$route.query.seedtypeid);
    }
    let query = this.routerReplacer();
    await DSADStore.getAdminAllocation(query);
    this.loading = false;
  }
}
