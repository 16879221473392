


























































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { Demand, Supply } from "@/store/models/DemandAndSupply";
import {
  CropVarietyStore,
  CropStore,
  DemandSupplyStore,
  MetaStore,
  JWTStore,
  UserStore
} from "@/store/modules";
import { MetaInfo } from "vue-meta";
import { convertADtoBS, nepaliDate } from "@/store/modules/dateConverter";
import UserDetailDocument from "@/views/Admin/Components/UserDetailDocument.vue";
import { SubsidyDocument } from "@/store/models/crop";
import { roleChecker } from "@/utils/jwt";

import DetailTag from "@/components/UIComponents/DetailTag.vue";

import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";
import { MessageBoxData } from "element-ui/types/message-box";

@Component({
  components: {
    DetailTag,
    UserDetailDocument
  },
  metaInfo(): MetaInfo {
    return {
      title: "Supply List",
      meta: [
        { name: "keyword", content: "Sqcc demand list" },
        {
          name: "description",
          content: "List of Demands of seeds",
          vmid: "demandList"
        }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchCompany: string | null = null;
  searchCode: string | null = null;
  fiscalYearId: number | null = null;
  cropId: number | null = null;
  cropVarietyId: number | null = null;
  seedTypeId: number | null = null;
  districtId: number | null = null;
  pageNum: number = 1;
  clusterId: number = 0;
  language: string = "";
  loading: boolean = false;
  status: string | null = null;
  subsidyStatus: string | null = null;
  role: string = "";
  dialogVisible: boolean = false;
  documentVisible: boolean = false;
  documentLoading: boolean = false;
  currentData: Supply = {} as Supply;

  message: string = "";

  get currentSubsidyUser() {
    return UserStore.UserDetail;
  }

  get dataList() {
    return DemandSupplyStore.PaginatedApproveAllocation;
  }

  unitName: any = {
    pkg: `${this.$t("per")} ${this.$t("kg")}`,
    pcent: `${this.$t("per")} %`
  };

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne" && value) {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  // get akcDataList(){
  //   let paginatedSupply = DemandSupplyStore.PaginatedSupply
  //   let suppliers = paginatedSupply.results.filter(x => x.company_district == this.user.district )
  //   console.log("supsakc -> ",suppliers)
  //   return suppliers
  // }

  roleChecker(roles: string[]) {
    return roleChecker(roles);
  }

  indexMethod(index: number) {
    let i = (this.dataList.page - 1) * this.dataList.page_size + index + 1;
    return this.nepaliUnicodeNumber(i);
  }

  nepaliDate(date: string) {
    return nepaliDate(date, this.$i18n.locale);
  }

  get districts() {
    return MetaStore.District;
  }

  get fiscalyears() {
    return MetaStore.FiscalYear;
  }

  get crops() {
    let crops = DemandSupplyStore.FyWiseSubsidyCrops.map(x => {
      return { fiscal_year: x.fiscal_year, name: x.crop_name, id: x.crop_type };
    });
    if (this.fiscalYearId) {
      crops = crops.filter(x => x.fiscal_year == this.fiscalYearId);
    }
    crops = [...new Map(crops.map(item => [item["id"], item])).values()];
    return crops;
  }

  get cropVarieties() {
    let cropVarieties = DemandSupplyStore.FyWiseSubsidy.map(x => {
      return {
        fiscal_year: x.fiscal_year,
        crop_type: x.crop_type,
        name: x.variety_name,
        id: x.crop_variety
      };
    });
    if (this.fiscalYearId || this.cropId) {
      if (this.fiscalYearId) {
        cropVarieties = cropVarieties.filter(x => x.fiscal_year == this.fiscalYearId);
      }
      if (this.cropId) {
        cropVarieties = cropVarieties.filter(x => x.crop_type == this.cropId);
      }
    }
    // Returns only unique variety
    cropVarieties = [...new Map(cropVarieties.map(item => [item["id"], item])).values()];
    return cropVarieties;
  }

  get seedTypes() {
    let seedTypes = DemandSupplyStore.SeedTypeList;
    if (this.roleChecker(["source_seed_producer", "SSSMAdmin"])) {
      seedTypes = seedTypes.filter(x => [2, 3].includes(x.id));
    }
    return seedTypes;
  }

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  get user() {
    console.log("user", UserStore.User);
    return UserStore.User;
  }

  changeCrop() {
    this.cropVarietyId = null;
  }

  thousandSeperator(amount: number) {
    if (amount == null) {
      return null;
    }
    amount = Number(amount);
    if (amount !== undefined || amount !== 0 || amount !== "0" || amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  search() {
    let query: any = { page: 1 };
    this.pageNum = 1;
    // if (this.fiscalYearId != null) {
    //   query["fiscalYearId"] = String(this.fiscalYearId);
    // }
    // if (this.cropId != null) {
    //   query["cropId"] = String(this.cropId);
    // }
    // if (this.cropVarietyId != null) {
    //   query["cropVarietyId"] = String(this.cropVarietyId);
    // }
    // if (this.seedTypeId != null) {
    //   query["seedTypeId"] = String(this.seedTypeId);
    // }
    // if (this.districtId != null) {
    //   query["districtId"] = String(this.districtId);
    // }
    // if (this.status != null) {
    //   query["status"] = String(this.status);
    // }
    // if (this.searchCompany != null) {
    //   query["searchCompany"] = String(this.searchCompany);
    // }
    // if (this.searchCode != null) {
    //   query["searchCode"] = String(this.searchCode);
    // }
    query = this.routerReplacer();
    DemandSupplyStore.getApproveAllocations(query);
  }

  clearSearch() {
    let query: any = { page: 1 };
    this.pageNum = 1;
    this.fiscalYearId = null;
    this.cropId = null;
    this.cropVarietyId = null;
    this.seedTypeId = null;
    this.districtId = null;
    this.status = null;
    this.searchCode = null;
    this.searchCompany = null;
    query = this.routerReplacer();
    DemandSupplyStore.getApproveAllocations(query);
  }

  routerReplacer() {
    let query: any = {};
    if (this.pageNum) {
      query["page"] = String(this.pageNum);
    }
    if (this.fiscalYearId != null) {
      query["fiscalYearId"] = String(this.fiscalYearId);
    }
    if (this.cropId != null) {
      query["cropId"] = String(this.cropId);
    }
    if (this.cropVarietyId != null) {
      query["cropVarietyId"] = String(this.cropVarietyId);
    }
    // if role is ISMAdmin (Improved Seed Manager Admin), then pass seedTypeId = 4 (id of seedtype 'Improved Seed' )
    if (this.role == "ISMAdmin") {
      query["seedTypeId"] = String(4);
    } else if (this.seedTypeId != null) {
      query["seedTypeId"] = String(this.seedTypeId);
    }
    if (this.districtId != null) {
      query["districtId"] = String(this.districtId);
    }
    if (this.status != null) {
      query["status"] = String(this.status);
    }
    if (this.subsidyStatus != null) {
      query["subsidyStatus"] = String(this.subsidyStatus);
    }
    if (this.searchCompany != null) {
      query["searchCompany"] = String(this.searchCompany);
    }
    if (this.searchCode != null) {
      query["searchCode"] = String(this.searchCode);
    }
    if (this.$i18n.locale != null) {
      query["lang"] = String(this.$i18n.locale);
    }
    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: query
        })
        .catch(error => {});
    }
    return query;
  }

  async handlePageChange(val: number) {
    this.pageNum = val;
    this.loading = true;
    let query = this.routerReplacer();
    await DemandSupplyStore.getApproveAllocations(query);
    this.loading = false;
  }

  toogleExpand(row: any) {
    let $table = this.$refs.dataTable as any;
    $table.toggleRowExpansion(row);
  }

  viewBill() {
    this.dialogVisible = true;
  }

  get subsidyDocument() {
    return DemandSupplyStore.CurrentSubsidyDocument;
  }

  async showDocument(row: Supply) {
    this.documentVisible = true;
    this.documentLoading = true;
    this.currentData = row;
    await DemandSupplyStore.getSubsidyDocument({ subsidyBatchId: row.subsidy_batch });
    if (row.seedcompanyslug) {
      await UserStore.getCompanyByUserSlug(row.seedcompanyslug);
    }
    this.documentLoading = false;
  }

  async approveSupply(id: number) {
    this.$confirm(this.$t("corfirmApproval").toString(), "", {
      confirmButtonText: this.$t("yes").toString(),
      confirmButtonClass: "el-button--success",
      cancelButtonText: this.$t("no").toString(),
      showClose: false,
      type: "warning"
    })

      .then(async () => {
        await DemandSupplyStore.approveSupplyById({ id: id, lang: this.$i18n.locale });
        this.documentVisible = false;
      })

      .catch(() => {
        this.$message({
          type: "info",
          message: this.$t("cancelled").toString()
        });
      });
  }

  rejectMessageValidator(value: any) {
    if (!value || value == "" || value == " ") {
      return false;
    }
    return true;
  }

  async rejectSupply(id: number) {
    this.$prompt(this.$t("rejectionReason").toString(), "", {
      confirmButtonText: this.$t("reject").toString(),
      confirmButtonClass: "el-button--danger",
      cancelButtonText: this.$t("cancel").toString(),
      showClose: false,
      type: "warning",
      inputValidator: this.rejectMessageValidator,
      inputType: "textarea",
      inputErrorMessage:
        this.$i18n.locale == "ne"
          ? "Reason for rejection is required"
          : "अस्वीकृत हुनको कारण आवाश्यक छ",
      inputPlaceholder: this.$t("rejectionReason").toString()
    })
      .then(async (p: any) => {
        if (p.value) {
          await DemandSupplyStore.rejectSupplyById({
            id: id,
            message: p.value,
            lang: this.$i18n.locale
          });
        }
        this.documentVisible = false;
      })

      .catch(() => {
        this.$message({
          type: "info",
          message: this.$t("cancelled").toString()
        });
      });
  }

  async created() {
    MetaStore.getAllDistrict();
    MetaStore.getAllFiscalYear();
    await DemandSupplyStore.getFyWiseSubsidyCrops({ lang: this.$i18n.locale });
    await DemandSupplyStore.getFyWiseSubsidy({ lang: this.$i18n.locale });
    DemandSupplyStore.getAllSeedType();
    await UserStore.getUser();

    this.role = JWTStore.role;
    this.language = this.$i18n.locale;

    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }
    if (this.$route.query.cropId) {
      this.cropId = Number(this.$route.query.cropId);
    }
    if (this.$route.query.cropVarietyId) {
      this.cropVarietyId = Number(this.$route.query.cropVarietyId);
    }
    if (this.$route.query.seedTypeId) {
      this.seedTypeId = Number(this.$route.query.seedTypeId);
    }
    if (this.$route.query.status) {
      this.status = String(this.$route.query.status);
    }
    if (this.$route.query.searchCompany) {
      this.searchCompany = String(this.$route.query.searchCompany);
    }
    if (this.$route.query.searchCode) {
      this.searchCode = String(this.$route.query.searchCode);
    }
    let query = this.routerReplacer();

    this.loading = true;
    await DemandSupplyStore.getApproveAllocations(query);
    this.loading = false;
  }

  getSummaries(param: any) {
    const { columns, data } = param;
    const sums: (number | string)[] = [];
    columns.forEach((column: any, index: number) => {
      if (index === 0) {
        sums[index] = this.$t("grandTotal").toString();
        return;
      } else {
        sums[index] = "";
      }

      if (index === 1 || index === 2) {
        const values = data.map((item: any) => Number(item[column.property]));
        const sum = values.reduce((prev: any, curr: any) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);
        sums[index] = `${nepaliUnicodeNumber(sum)}`;
        return;
      }

      if (index == 3) {
        const verifiedSum = data
          .filter((x: any) => x.document_submitted)
          .map((item: any) => Number(item.sold_quantity))
          .reduce((prev: any, curr: any) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        sums[index] = `${nepaliUnicodeNumber(verifiedSum)}`;
        return;
      }

      if (index === 4) {
        const value = data[0]["subsidy_value"];
        sums[index] = `${this.$t("grandTotal")} ${this.$t("subsidy")}: ${this.$t(
          "Rs"
        )} ${nepaliUnicodeNumber(value)}`;
        return;
      }
    });
    return sums;
  }
}
