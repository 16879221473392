


















































































































































































































import { Vue, Component } from "vue-property-decorator";
import { UserStore, JWTStore, MetaStore } from "@/store/modules";
import { User } from "@/store/models/user";
import ApprovedUsers from "@/views/Admin/Components/ApprovedUsers.vue";
import { eventHub } from "@/utils/eventHub";
import { roleChecker } from "@/utils/jwt";

@Component({
  components: {
    ApprovedUsers
  },
  metaInfo: {
    title: "Approved User List",
    meta: [
      { name: "keyword", content: "Sqcc user list" },
      {
        name: "description",
        content: "List of approved users of SQCC",
        vmid: "userList"
      }
    ]
  }
})
export default class ApprovedUser extends Vue {
  dialogVisible: boolean = false;
  innerVisible: boolean = false;
  loading: boolean = false;
  selectedIndex = -1;
  data: User = {} as User;
  activeName: string = "source_seed_producer";
  searchString: string = "";
  formVisible: any = {
    demandCompany: false,
    seedCompany: false,
    general: false,
    admin: false
  };
  demandCompany: string = "Demand Company";
  demandSupplyCompany: string = "Supply/Demand Company";

  selectedProvince: number | null = null;
  selectedDistrict: number | null = null;

  roleChecker(roles: string[]) {
    return roleChecker(roles);
  }

  get dataList() {
    return UserStore.Users;
  }

  get provinces() {
    return MetaStore.Province;
  }

  get districts() {
    let district = MetaStore.District;
    if (this.selectedProvince) {
      district = district.filter(x => x.provinceid == this.selectedProvince);
    } else {
      district = [];
    }
    return district;
  }

  get activeTab() {
    return this.activeName;
  }

  get role() {
    return JWTStore.role;
  }

  get ApprovedSSPUsers() {
    return UserStore.ApprovedSSPUsers;
  }

  get ApprovedISPUsers() {
    return UserStore.ApprovedISPUsers;
  }

  get ApprovedISDUsers() {
    return UserStore.ApprovedISDUsers;
  }

  get ApprovedISCUsers() {
    return UserStore.ApprovedISCUsers;
  }

  set activeTab(tab: string) {
    this.activeName = tab;
    this.$router
      .replace({
        name: "ApprovedUsers",
        query: { activeTab: this.activeName }
      })
      .catch((reason: any) => {});
  }

  addUser() {
    this.$validator.reset();
    if (this.activeTab === "source_seed_producer") {
      this.$router
        .push({
          name: "RegisterSourceSeedProducer"
        })
        .catch((reason: any) => {});
    } else if (this.activeTab === "improved_seed_producer") {
      this.$router
        .push({
          name: "RegisterImprovedSeedProducer"
        })
        .catch((reason: any) => {});
    } else if (this.activeTab === "improved_seed_distributor") {
      this.$router
        .push({
          name: "RegisterImprovedSeedDistributor"
        })
        .catch((reason: any) => {});
    } else if (this.activeTab === "improved_seed_consumer") {
      this.$router
        .push({
          name: "RegisterImprovedSeedConsumer"
        })
        .catch((reason: any) => {});
    }
  }

  async submit() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      this.dialogVisible = false;
    } else {
      this.$message.error("Form  Invalid");
    }
  }

  changeDemandCompanyTabName(obj: string) {
    this.demandCompany = "Demand Company - (" + obj + ")";
    if (obj == undefined) {
      this.demandCompany = "Demand Company";
    }
  }

  changeSupplyDemandCompanyTabName(obj: string) {
    this.demandSupplyCompany = "Supply/Demand Company - (" + obj + ")";
    if (obj == undefined) {
      this.demandSupplyCompany = "Supply/Demand Company";
    }
  }

  private created() {
    UserStore.getApprovedSSPUserList();
    UserStore.getApprovedISPUserList();
    UserStore.getApprovedISDUserList();
    UserStore.getApprovedISCUserList();

    MetaStore.getAllProvince();
    MetaStore.getAllDistrict();

    eventHub.$on("changeDemandCompanyTabName", this.changeDemandCompanyTabName);
    eventHub.$on("changeSupplyDemandCompanyTabName", this.changeSupplyDemandCompanyTabName);
  }

  mounted() {
    if (this.$route.query.activeTab) {
      this.activeTab = String(this.$route.query.activeTab);
    } else if (roleChecker(["ISSMAdmin"])) {
      this.activeTab = "improved_seed_producer";
    } else if (roleChecker(["DLAO"])) {
      this.activeTab = "improved_seed_producer";
    }
  }

  async handlePrevNextClick(val: any, list: string, approved?: any) {
    this.loading = true;
    if (list == "source_seed_producer") {
      await UserStore.getApprovedSSPUserList({
        searchString: this.searchString,
        provinceId: this.selectedProvince,
        districtId: this.selectedDistrict,
        page: val
      });
    } else if (list == "improved_seed_producer") {
      await UserStore.getApprovedISPUserList({
        searchString: this.searchString,
        provinceId: this.selectedProvince,
        districtId: this.selectedDistrict,
        page: val
      });
    } else if (list == "improved_seed_distributor") {
      await UserStore.getApprovedISDUserList({
        searchString: this.searchString,
        provinceId: this.selectedProvince,
        districtId: this.selectedDistrict,
        page: val
      });
    } else if (list == "improved_seed_consumer") {
      await UserStore.getApprovedISCUserList({
        searchString: this.searchString,
        provinceId: this.selectedProvince,
        districtId: this.selectedDistrict,
        page: val
      });
    } else {
      console.log(list);
    }
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    this.loading = false;
  }

  searchText() {
    let query = {
      searchString: this.searchString,
      provinceId: this.selectedProvince,
      districtId: this.selectedDistrict,
      page: 1
    };
    UserStore.getApprovedSSPUserList(query);
    UserStore.getApprovedISPUserList(query);
    UserStore.getApprovedISDUserList(query);
    UserStore.getApprovedISCUserList(query);
  }

  clearFilter() {
    this.selectedProvince = null;
    this.selectedDistrict = null;
    this.searchString = "";
    UserStore.getApprovedSSPUserList();
    UserStore.getApprovedISPUserList();
    UserStore.getApprovedISDUserList();
    UserStore.getApprovedISCUserList();
  }

  changeProvince() {
    this.selectedDistrict = null;
  }

  clearProvince() {
    this.selectedProvince = null;
    this.selectedDistrict = null;
  }
}
